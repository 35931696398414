import { createSelector } from "reselect";

const villasState = state => state.villas;

export const shortlistSelector = createSelector([villasState], villasState => {
  return {
    show: villasState.showShortlist,
    shortlist: villasState.shortlist
  };
});
