import React from "react";
import { render } from "react-snapshot";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import { init } from "@sentry/browser";

import store from "./store";

import NotFound from "./screens/not-found";
import Search from "./screens/search";

import Cookies from "./modules/cookies/cookies-component";
import Navigation from "./modules/navigation/navigation-component";
import Footer from "./modules/footer/footer-component";
import Loading from "./modules/loading/loading-component";

import ScrollToTop from "./components/scroll-top";
import ErrorBoundary from "./components/error";

import "./styles/base.scss";
import SEO from "./components/seo";

// Sentry logging
if (process.env.REACT_APP_SENTRY_DSN) {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENV || "development"
  });
}

const About = Loadable({
  loader: () => import("./screens/about"),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import("./screens/dashboard"),
  loading: Loading
});

const Auth = Loadable({
  loader: () => import("./screens/auth"),
  loading: Loading
});

const Collections = Loadable({
  loader: () => import("./screens/collections/"),
  loading: Loading
});

const Home = Loadable({
  loader: () => import("./screens/home"),
  loading: Loading
});

const Offers = Loadable({
  loader: () => import("./screens/offers"),
  loading: Loading
});

const Shortlist = Loadable({
  loader: () => import("./screens/shortlist"),
  loading: Loading
});

const TravelGuide = Loadable({
  loader: () => import("./screens/travel-journal"),
  loading: Loading
});

const VillasRouter = Loadable({
  loader: () => import("./screens/villas/"),
  loading: Loading
});

const App = () => (
  <ErrorBoundary>
    <SEO title="Luxury villa rentals in Greece, Portugal and Spain" />
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Cookies />
          <Navigation />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/collections" component={Collections} />
            <Route path="/auth" component={Auth} />
            <Route path="/about" component={About} />
            <Route path="/s" component={Search} />
            <Route path="/offers" component={Offers} />
            <Route path="/villas" component={VillasRouter} />
            <Route exact path="/travel-journal" component={TravelGuide} />
            <Route path="/shortlist" component={Shortlist} />
            <Route path="/dashboard" component={Dashboard} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);

render(<App />, document.getElementById("root"));
