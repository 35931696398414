import React from "react";

import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getShortlist, hideShortlist } from "../../store/villas.redux";

import { shortlistSelector } from "./shortlist.selector";

class ShortListDropdown extends React.Component {
  handleClickOutside = () => {
    // ..handling code goes here...
    this.props.hideShortlist();
  };

  render() {
    if (!this.props.show || this.props.shortlist < 1) return null;
    const { shortlist } = this.props;
    const { name } = shortlist[0];
    return (
      <div className="shortlist">
        <p>
          <strong>{name}</strong> has been added to your shortlist.
        </p>
        {/* <p><small>Keep your shortlist in sync with other devices by logging in.</small></p> */}
        <button onClick={this.props.hideShortlist} className="full-width">
          Hide notification
        </button>
      </div>
    );
  }
}

ShortListDropdown.propTypes = {
  show: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ hideShortlist, getShortlist }, dispatch);
export default withRouter(
  connect(
    shortlistSelector,
    mapDispatchToProps
  )(onClickOutside(ShortListDropdown))
);
