import React, { Fragment } from "react";
import PropType from "prop-types";
import { Route, Switch } from "react-router-dom";

const ShowCollection = ({ match }) => {
  const { collection } = match.params;

  return (
    <Fragment>
      <div className="promo">
        <div className="text">
          <h1>{collection}</h1>
          <p>
            Whatever the season or reason, check out our unique villa
            collections for a little inspiration.
          </p>
        </div>
      </div>
      <div className="main space-top">
        {/* <div className="heading"> */}
        <h1>Collections</h1>
        <p>Looking for a little inspiration? </p>
        {/* </div> */}
      </div>
    </Fragment>
  );
};

ShowCollection.propTypes = {
  match: PropType.any
};

const ShowAll = () => (
  <Fragment>
    <div className="promo">
      <div className="text">
        <h1>Our Collections</h1>
        <p>
          Whatever the season or reason, check out our unique villa collections
          for a little inspiration.
        </p>
      </div>
    </div>
    <div className="main space-top">
      {/* <div className="heading"> */}
      <h1>Collections</h1>
      <p>Looking for a little inspiration? </p>
      {/* </div> */}
    </div>
  </Fragment>
);

const Search = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/:collection`} component={ShowCollection} />
      <Route exact path={match.path} component={ShowAll} />
    </Switch>
  );
};

Search.propTypes = {
  match: PropType.any
};

export default Search;
