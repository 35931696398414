const actions = {
  CLOSE_ACTIVE_FILTER: "SEARCH/CLOSE_ACTIVE_FILTER",
  GUEST_DECREMENT: "SEARCH/GUEST_DECREMENT",
  GUEST_INCREMENT: "SEARCH/GUEST_INCREMENT",
  GUEST_RESET: "SEARCH/GUEST_RESET",
  OPEN_FILTER: "SEARCH/OPEN_FILTER",
  RESET: "SEARCH/RESET"
};

const initialState = {
  guests: {
    adults: 0,
    children: 0,
    infants: 0,
    total: 0
  },
  activeFilter: null
};

const closeActiveFilter = () => ({
  type: actions.CLOSE_ACTIVE_FILTER
});

const openFilter = id => ({
  type: actions.OPEN_FILTER,
  id
});

const resetFilters = () => ({
  type: actions.RESET
});

const incrementGuests = id => ({
  type: actions.GUEST_INCREMENT,
  id
});

const decrementGuests = id => ({
  type: actions.GUEST_DECREMENT,
  id
});

const resetGuests = () => ({
  type: actions.GUEST_RESET
});

const updateGuests = (state = {}, action) => {
  const { id } = action;
  const { guests } = state;

  const current = guests[id];

  switch (action.type) {
    case actions.GUEST_DECREMENT: {
      if (current === 0) return state;
      return {
        ...state,
        guests: {
          ...guests,
          [id]: current - 1,
          total: guests.total - 1
        }
      };
    }
    case actions.GUEST_INCREMENT: {
      return {
        ...state,
        guests: {
          ...guests,
          [id]: current + 1,
          total: guests.total + 1
        }
      };
    }
    default:
      return state;
  }
};

const searchReducer = (state = initialState, action) => {
  const { id } = action;
  switch (action.type) {
    case actions.GUEST_DECREMENT:
    case actions.GUEST_INCREMENT: {
      return updateGuests(state, action);
    }
    case actions.OPEN_FILTER: {
      return {
        ...state,
        activeFilter: state.activeFilter !== id ? id : null
      };
    }
    case actions.CLOSE_ACTIVE_FILTER: {
      return {
        ...state,
        activeFilter: null
      };
    }
    case actions.GUEST_RESET: {
      return {
        ...state,
        guests: initialState.guests
      };
    }
    case actions.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export {
  actions,
  closeActiveFilter,
  decrementGuests,
  incrementGuests,
  openFilter,
  resetGuests,
  resetFilters,
  searchReducer
};
