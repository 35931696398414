import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropType from "prop-types";

import {
  checkCookieNotification,
  dismissCookieNotification
} from "../../store/cookies-redux";

import { cookiesSelector } from "../../store/cookies-selector";

class CookieNotification extends Component {
  componentDidMount() {
    this.props.checkCookieNotification();
  }

  static propTypes = {
    cookies: PropType.shape({
      show: PropType.bool.isRequired
    }).isRequired,
    checkCookieNotification: PropType.func.isRequired,
    dismissCookieNotification: PropType.func.isRequired
  };

  render() {
    const {
      dismissCookieNotification,
      cookies: { show }
    } = this.props;

    if (!show) return null;

    return (
      <div className="cookie-notice">
        <div>
          <p>
            This site uses cookies to help personalise and improve the content
            on this website. <Link to="/about/privacy/">Privacy Policy</Link>.
          </p>
        </div>
        <div>
          <button className="button" onClick={dismissCookieNotification}>
            Hide notice
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { checkCookieNotification, dismissCookieNotification },
    dispatch
  );
export default withRouter(
  connect(cookiesSelector, mapDispatchToProps)(CookieNotification)
);
