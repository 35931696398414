import React from "react";
import { PulseLoader as Loader } from "react-spinners";
import NotFound from "../../screens/not-found";

const Loading = ({ error }) => {
  if (error) {
    return <NotFound />;
  } else {
    return (
      <div className="strip">
        <div className="main">
          <div className="loading">
            <center>
              <Loader size={10} />
            </center>
          </div>
        </div>
      </div>
    );
  }
};

export default Loading;
