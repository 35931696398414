import { API_ENDPOINT } from "../config";
import Axios from "axios";

const actions = {
  SET_ACCOUNT: "ACCOUNT/SET_ACCOUNT",
  SET_ADMIN: "ACCOUNT/SET_ADMIN",
  SET_CHECKED: "ACCOUNT/SET_CHECKED"
};

const initialState = {
  account: null,
  checked: false,
  authenticated: false,
  isAdmin: false
};

const getAccount = () => dispatch => {
  Axios.get(`${API_ENDPOINT}/account/me`, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
        dispatch(setAccount(null));
        dispatch(setAdmin(false));
      }
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .then(account => {
      dispatch(setAccount(account));
      if (account.role === "ADMIN") {
        dispatch(setAdmin(true));
      } else {
        dispatch(setAdmin(false));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setChecked(true));
    });
};

const setAccount = account => ({
  type: actions.SET_ACCOUNT,
  account
});

const setAdmin = isAdmin => ({
  type: actions.SET_ADMIN,
  isAdmin
});

const setChecked = checked => ({
  type: actions.SET_CHECKED,
  checked
});

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ACCOUNT: {
      return {
        ...state,
        account: action.account
      };
    }
    case actions.SET_ADMIN: {
      return {
        ...state,
        isAdmin: action.isAdmin
      };
    }
    case actions.SET_CHECKED: {
      return {
        ...state,
        checked: action.checked
      };
    }
    default:
      return state;
  }
};

export { actions, accountReducer, getAccount };
