import { createSelector } from "reselect";

const villasState = state => state.villas;

export const navigationSelector = createSelector([villasState], villasState => {
  return {
    showMenu: villasState.showMenu,
    show: villasState.showShortlist,
    shortlistCount: villasState.shortlist.length
  };
});
