import { createSelector } from "reselect";

export const cookiesSelector = createSelector(
  [state => state.cookies],
  cookies => {
    return {
      cookies
    };
  }
);
