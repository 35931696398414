import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

// modules
import { cookiesReducer as cookies } from "./cookies-redux";
import { shortlistReducer as shortlist } from "./shortlist-redux";
import { collectionsReducer as collections } from "./collections.redux";
import { searchReducer as search } from "./search.redux";
import { villasReducer as villas } from "./villas.redux";
import { accountReducer as account } from "./account.redux";
import { adminReducer as admin } from "./admin.redux";

const rootReducer = combineReducers({
  admin,
  account,
  cookies,
  collections,
  search,
  shortlist,
  villas
});

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
