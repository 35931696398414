import React from "react";

const NotFound = () => (
  <div className="promo">
    <div className="text">
      <h1>{"Error 404: This probably isn't the page you were looking for."}</h1>
      <p>
        We did our best, but we could not seem to find the right page you
        looking for.
      </p>
    </div>
  </div>
);

export default NotFound;
