import { getItem, setItem } from "../utils/local-storage";

const actions = {
  ADD_TO_SHORTLIST: "SHORTLIST/ADD_TO_SHORTLIST",
  GET_SHORTLIST: "SHORTLIST/GET_SHORTLIST",
  TOGGLE_SHORTLIST: "SHORTLIST/TOGGLE_SHORTLIST",
  SHOW_SHORTLIST: "SHORTLIST/SHOW_SHORTLIST"
};

const initialState = {
  show: false,
  villas: []
};

const addToShortlist = id => (dispatch, getState) => {
  const {
    shortlist: { villas }
  } = getState();
  const shortlist = [...villas, id];
  setItem("shortlist", shortlist).then(() => {
    dispatch(showShortlist());
    dispatch({
      type: actions.ADD_TO_SHORTLIST,
      villas: shortlist
    });
  });
};

const getShortlist = () => (dispatch, getState) => {
  getItem("shortlist").then(villas => {
    dispatch({
      type: actions.GET_SHORTLIST,
      villas: villas || []
    });
  });
};

const showShortlist = () => ({
  type: actions.SHOW_SHORTLIST,
  showShortlist: true
});

const toggleShortlist = () => ({
  type: actions.TOGGLE_SHORTLIST
});

const shortlistReducer = (state = initialState, action) => {
  const { villas, showShortlist } = action;
  switch (action.type) {
    case actions.SHOW_SHORTLIST: {
      return { ...state, show: showShortlist };
    }
    case actions.TOGGLE_SHORTLIST: {
      const { show } = state;
      return { ...state, show: !show };
    }
    case actions.ADD_TO_SHORTLIST: {
      return { ...state, villas };
    }
    case actions.GET_SHORTLIST: {
      return { ...state, villas };
    }
    default:
      return state;
  }
};

export {
  actions,
  addToShortlist,
  getShortlist,
  toggleShortlist,
  shortlistReducer
};
