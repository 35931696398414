import { getItem, setItem } from "../utils/local-storage";
import { API_ENDPOINT } from "../config";
import Axios from "axios";

const allVillasKey = "allVillas";
const destinationsKey = "destinations";
const latestVillasKey = "latestVillas";
const popularVillasKey = "popularVillas";
const shortlistIdsKey = "shortlistIds";
const shortlistVillasKey = "shortlistVillas";
const recentIdsKey = "recentIds";
const recentVillasKey = "recentVillas";

const actions = {
  ADD_TO_RECENT: "VILLAS/ADD_TO_RECENT",
  ADD_TO_SHORTLIST: "VILLAS/ADD_TO_SHORTLIST",
  SET_LOADING: "VILLAS/SET_LOADING",
  GET_RECENT: "VILLAS/GET_RECENT",
  GET_SHORTLIST: "VILLAS/GET_SHORTLIST",
  SET_DESTINATIONS: "VILLAS/SET_DESTINATIONS",
  SET_LATEST: "VILLAS/SET_LATEST",
  SET_POPULAR: "VILLAS/SET_POPULAR",
  SET_RECENT: "VILLAS/SET_RECENT",
  SET_SHORTLIST: "VILLAS/SET_SHORTLIST",
  SET_THUMBNAIL: "VILLAS/SET_THUMBNAIL",
  SET_VILLAS: "VILLAS/SET_VILLAS",
  SET_SUBMITTED: "VILLAS/SET_SUBMITTED",
  SHOW_SHORTLIST: "VILLAS/SHOW_SHORTLIST",
  SHOW_MENU: "VILLAS/SHOW_MENU"
};

const initialState = {
  destinations: [],
  items: [],
  filteredItems: [],
  galleryOpen: false,
  gallerySelected: 0,
  latestItems: [],
  loading: false,
  recent: [],
  shortlist: [],
  popular: [],
  submitted: false,
  showShortlist: false,
  showMenu: false
};

const getAllVillas = () => dispatch => {
  dispatch(setLoading(true));
  getItem(allVillasKey).then(localVillas => {
    if (localVillas) {
      // dispatch(setAllVillas(localVillas))
    }
    Axios.get(`${API_ENDPOINT}/villas/all`).then(({ data: allVillas }) => {
      setItem(allVillasKey, allVillas).then(() => {
        dispatch(setAllVillas(allVillas));
        dispatch(setLoading(false));
      });
    });
  });
};

const getLatestVillas = () => dispatch => {
  dispatch(setLoading(true));
  getLocalLatest().then(localLatest => {
    if (localLatest) {
      // dispatch(setLatest(localLatest))
    }
    Axios.get(`${API_ENDPOINT}/villas/latest`).then(({ data: latest }) => {
      setItem(latestVillasKey, latest).then(() => {
        dispatch(setLatest(latest));
        dispatch(setLoading(false));
      });
    });
  });
};

const toggleMenu = () => (dispatch, getState) => {
  const {
    villas: { showMenu }
  } = getState();
  dispatch({
    type: actions.SHOW_MENU,
    showMenu: !showMenu
  });
};

const getPopularVillas = () => dispatch => {
  dispatch(setLoading(true));
  getLocalPopular().then(localPopular => {
    if (localPopular) {
      // dispatch(setPopular(localPopular))
    }
    Axios.get(`${API_ENDPOINT}/villas/popular`).then(({ data: popular }) => {
      setItem(popularVillasKey, popular).then(() => {
        dispatch(setPopular(popular));
        dispatch(setLoading(false));
      });
    });
  });
};

const getDestinations = () => dispatch => {
  dispatch(setLoading(true));
  getItem(destinationsKey).then(localDestinations => {
    if (localDestinations) {
      // dispatch(setDestinations(localDestinations))
    }
    Axios.get(`${API_ENDPOINT}/destinations/all`).then(
      ({ data: destinations }) => {
        setItem(destinationsKey, destinations).then(() => {
          dispatch(setDestinations(destinations));
          dispatch(setLoading(false));
        });
      }
    );
  });
};

const submitEnquiry = enquiry => dispatch => {
  dispatch(setLoading(true));
  return Axios.post(`${API_ENDPOINT}/enquiry/send`, enquiry);
};

const openGallery = id => ({
  type: actions.SET_THUMBNAIL,
  id
});

const nextImage = () => (dispatch, getState) => {
  const {
    villas: { gallerySelected }
  } = getState();
  const id = gallerySelected + 1;
  dispatch({
    type: actions.SET_THUMBNAIL,
    id
  });
};

const previousImage = () => (dispatch, getState) => {
  const {
    villas: { gallerySelected }
  } = getState();
  const id = gallerySelected - 1;
  dispatch({
    type: actions.SET_THUMBNAIL,
    id
  });
};

const closeGallery = () => ({
  type: actions.SET_THUMBNAIL,
  id: null
});

const getLocalLatest = () => getItem(latestVillasKey);

const getLocalPopular = () => getItem(popularVillasKey);

const setDestinations = destinations => ({
  type: actions.SET_DESTINATIONS,
  destinations
});

const setLatest = latest => ({
  type: actions.SET_LATEST,
  latest
});

const setPopular = popular => ({
  type: actions.SET_POPULAR,
  popular
});

const setAllVillas = villas => ({
  type: actions.SET_VILLAS,
  villas
});

const setRecent = recent => ({
  type: actions.SET_RECENT,
  recent
});

const setLoading = loading => ({
  type: actions.SET_LOADING,
  loading
});

const addToRecent = id => (dispatch, getState) => {
  getItem(recentIdsKey).then(recents => {
    let recent = (recents || []).filter(x => x !== id);
    recent.unshift(id);
    setItem(recentIdsKey, recent);
  });
};

const getRecent = () => (dispatch, getState) => {
  getItem(recentVillasKey).then(localRecents => {
    if (localRecents) {
      // dispatch(setRecent(localRecents))
    }

    getItem(recentIdsKey).then(villas => {
      if (!villas) return;
      dispatch(setLoading(true));
      Axios.post(`${API_ENDPOINT}/villas/shortlist`, { ids: villas }).then(
        ({ data: recent }) => {
          setItem(recentVillasKey, recent).then(() => {
            dispatch(setRecent(recent));
            dispatch(setLoading(false));
          });
        }
      );
    });
  });
};

const addToShortlist = id => dispatch => {
  getItem(shortlistIdsKey).then(shortlist => {
    let newShortlist = (shortlist || []).filter(x => x !== id);
    newShortlist.unshift(id);
    setItem(shortlistIdsKey, newShortlist).then(() => {
      dispatch(getShortlist());
      dispatch(showShortlist());
    });
  });
};

const removeFromShortlist = id => (dispatch, getState) => {
  getItem(shortlistIdsKey).then(shortlist => {
    let newShortlist = (shortlist || []).filter(x => x !== id);
    setItem(shortlistIdsKey, newShortlist).then(() => {
      dispatch(getShortlist());
    });
  });
};

const getShortlist = () => (dispatch, getState) => {
  getItem(shortlistVillasKey).then(localShortlist => {
    if (localShortlist) {
      // dispatch({
      //   type: actions.SET_SHORTLIST,
      //   shortlist: localShortlist || []
      // })
    }
    getItem(shortlistIdsKey).then(villas => {
      if (!villas || !villas.length) {
        dispatch({
          type: actions.SET_SHORTLIST,
          shortlist: []
        });
        return;
      }
      dispatch(setLoading(true));
      Axios.post(`${API_ENDPOINT}/villas/shortlist`, { ids: villas }).then(
        ({ data: shortlist }) => {
          setItem(shortlistVillasKey, shortlist).then(() => {
            dispatch({
              type: actions.SET_SHORTLIST,
              shortlist: shortlist || []
            });
            dispatch(setLoading(false));
          });
        }
      );
    });
  });
};

const showShortlist = () => ({
  type: actions.SHOW_SHORTLIST,
  showShortlist: true
});

const hideShortlist = () => ({
  type: actions.SHOW_SHORTLIST,
  showShortlist: false
});

const villasReducer = (state = initialState, action) => {
  const {
    latest,
    popular,
    destinations,
    id,
    loading,
    shortlist,
    showMenu,
    recent,
    villas
  } = action;
  switch (action.type) {
    case actions.SET_VILLAS: {
      return {
        ...state,
        items: villas
      };
    }
    case actions.SET_LATEST: {
      return {
        ...state,
        latestItems: latest
      };
    }
    case actions.SET_THUMBNAIL: {
      return {
        ...state,
        gallerySelected: id || 0,
        galleryOpen: id !== null
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading
      };
    }
    case actions.SHOW_SHORTLIST: {
      const { showShortlist } = action;
      return { ...state, showShortlist };
    }
    case actions.ADD_TO_SHORTLIST: {
      return { ...state, shortlist };
    }
    case actions.SET_SHORTLIST: {
      return { ...state, shortlist };
    }
    case actions.ADD_TO_RECENT: {
      return { ...state, recent };
    }
    case actions.SET_RECENT: {
      return { ...state, recent };
    }
    case actions.SET_POPULAR: {
      return { ...state, popular };
    }
    case actions.SET_DESTINATIONS: {
      return { ...state, destinations };
    }
    case actions.GET_RECENT: {
      return { ...state, recent };
    }
    case actions.SHOW_MENU: {
      return { ...state, showMenu };
    }
    default:
      return state;
  }
};

export {
  actions,
  addToRecent,
  addToShortlist,
  closeGallery,
  getAllVillas,
  getDestinations,
  getLatestVillas,
  getPopularVillas,
  getRecent,
  getShortlist,
  hideShortlist,
  nextImage,
  openGallery,
  previousImage,
  removeFromShortlist,
  submitEnquiry,
  showShortlist,
  toggleMenu,
  villasReducer
};
