import { getItem, setItem } from "../utils/local-storage";
import { API_ENDPOINT } from "../config";
import Axios from "axios";

const key = "collections";

const actions = {
  SET_COLLECTIONS: "COLLECTIONS/SET_COLLECTIONS",
  SET_LOADING: "COLLECTIONS/SET_LOADING"
};

const initialState = {
  items: [],
  loading: false
};

const getCollections = () => dispatch => {
  dispatch(setLoading(true));
  getLocalCollections().then(localCollections => {
    if (localCollections) {
      dispatch(setCollections(localCollections));
    }
    Axios.get(`${API_ENDPOINT}/collections/all`).then(
      ({ data: collections }) => {
        setItem(key, collections).then(() => {
          dispatch(setCollections(collections));
          dispatch(setLoading(false));
        });
      }
    );
  });
};

const getLocalCollections = () => getItem(key);

const setCollections = collections => ({
  type: actions.SET_COLLECTIONS,
  collections
});

const setLoading = loading => ({
  type: actions.SET_LOADING,
  loading
});

const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_COLLECTIONS: {
      const { collections } = action;
      return {
        ...state,
        items: collections
      };
    }
    case actions.SET_LOADING: {
      const { loading } = action;
      return {
        ...state,
        loading
      };
    }
    default:
      return state;
  }
};

export { actions, collectionsReducer, getCollections };
