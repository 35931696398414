import { API_ENDPOINT } from "../config";
import Axios from "axios";

const actions = {
  SET_AMENITIES: "ADMIN/SET_AMENITIES",
  SET_COLLECTIONS: "ADMIN/SET_COLLECTIONS",
  SET_DESTINATIONS: "ADMIN/SET_DESTINATIONS",
  SET_VILLAS: "ADMIN/SET_VILLAS",
  SET_BOOKINGS: "ADMIN/SET_BOOKINGS",
  SET_VILLA: "ADMIN/SET_VILLA",
  SET_ADMIN: "ADMIN/SET_ADMIN",
  SET_LOADING: "ADMIN/SET_LOADING"
};

const initialState = {
  loading: false
};

const getAmenities = () => dispatch => {
  dispatch(setLoading(true));
  Axios.get(`${API_ENDPOINT}/admin/listings/amenities`, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
      }

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.data;
    })
    .then(amenities => {
      dispatch(setAmenities(amenities));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const removeImage = id => dispatch => {
  dispatch(setLoading(true));
  Axios.delete(`${API_ENDPOINT}/admin/listings/images/${id}`, {
    withCredentials: true
  })
    .then(() => {
      // do something
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const setImageOrder = images => dispatch => {
  dispatch(setLoading(true));
  const orderedImages = images.map((image, position) => ({
    id: image.id,
    position: position + 1
  }));
  Axios.post(`${API_ENDPOINT}/admin/listings/images/order`, orderedImages, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
      }

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.data;
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const getCollections = () => dispatch => {
  dispatch(setLoading(true));
  Axios.get(`${API_ENDPOINT}/collections/all`)
    .then(response => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .then(collections => {
      dispatch(setCollections(collections));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const getDestinations = () => dispatch => {
  dispatch(setLoading(true));
  Axios.get(`${API_ENDPOINT}/admin/listings/destinations`, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
      }

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.data;
    })
    .then(destinations => {
      dispatch(setRegions(destinations));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const getVilla = id => dispatch => {
  dispatch(setLoading(true));
  Axios.get(`${API_ENDPOINT}/admin/listings/villas/${id}`, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
        // dispatch(setAccount(null))
        // dispatch(setAdmin(false))
      }
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .then(villa => {
      dispatch(setVilla(villa));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const updateVilla = payload => dispatch => {
  dispatch(setLoading(true));
  Axios.post(`${API_ENDPOINT}/admin/listings/villas/${payload.id}`, payload, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
      }

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const getVillas = () => dispatch => {
  dispatch(setLoading(true));
  Axios.get(`${API_ENDPOINT}/admin/listings/villas/all`, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
        // dispatch(setAccount(null))
        // dispatch(setAdmin(false))
      }
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .then(villas => {
      dispatch(setVillas(villas));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const getBookings = () => dispatch => {
  dispatch(setLoading(true));
  Axios.get(`${API_ENDPOINT}/admin/bookings/all`, {
    withCredentials: true
  })
    .then(response => {
      if (response.status === 401) {
        // dispatch(setAccount(null))
        // dispatch(setAdmin(false))
      }
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .then(bookings => {
      dispatch(setBookings(bookings));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const setAmenities = amenities => ({
  type: actions.SET_AMENITIES,
  amenities
});

const setCollections = collections => ({
  type: actions.SET_COLLECTIONS,
  collections
});

const setVilla = villa => ({
  type: actions.SET_VILLA,
  villa
});

const setVillas = villas => ({
  type: actions.SET_VILLAS,
  villas
});

const setBookings = bookings => ({
  type: actions.SET_BOOKINGS,
  bookings
});

const setRegions = destinations => ({
  type: actions.SET_DESTINATIONS,
  destinations
});

const setLoading = loading => ({
  type: actions.SET_LOADING,
  loading
});

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_AMENITIES: {
      return {
        ...state,
        amenities: action.amenities
      };
    }
    case actions.SET_COLLECTIONS: {
      return {
        ...state,
        collections: action.collections
      };
    }
    case actions.SET_DESTINATIONS: {
      return {
        ...state,
        destinations: action.destinations
      };
    }
    case actions.SET_VILLA: {
      return {
        ...state,
        villa: action.villa
      };
    }
    case actions.SET_BOOKINGS: {
      return {
        ...state,
        bookings: action.bookings
      };
    }
    case actions.SET_VILLAS: {
      return {
        ...state,
        villas: action.villas
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }
    default:
      return state;
  }
};

export {
  actions,
  adminReducer,
  getAmenities,
  getBookings,
  getCollections,
  getDestinations,
  getVilla,
  getVillas,
  removeImage,
  setImageOrder,
  updateVilla
};
