import { getItem, setItem } from "../utils/local-storage";

const actions = {
  CHECK_COOKIE_NOTIFICATION: "COOKIES/CHECK_COOKIE_NOTIFICATION",
  DISMISS_COOKIE_NOTIFICATION: "COOKIES/DISMISS_COOKIE_NOTIFICATION",
  SHOW_COOKIE_NOTIFICATION: "COOKIES/SHOW_COOKIE_NOTIFICATION"
};

const initialState = {
  show: false
};

const key = "cookie-notification";

const dismissCookieNotification = () => dispatch =>
  setItem(key, { show: false }).then(data =>
    dispatch(showCookieNotification(false))
  );

const checkCookieNotification = () => dispatch =>
  getItem(key).then(data => {
    if (!data) {
      return dispatch(showCookieNotification(true));
    }
    const { show } = data;
    dispatch(showCookieNotification(show));
  });

const showCookieNotification = show => ({
  type: actions.SHOW_COOKIE_NOTIFICATION,
  show
});

const cookiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_COOKIE_NOTIFICATION: {
      const { show } = action;
      return { ...state, ...{ show } };
    }
    default:
      return state;
  }
};

export {
  actions,
  checkCookieNotification,
  cookiesReducer,
  dismissCookieNotification
};
